@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap';
// regular style toast
// @import './../node_modules/ngx-toastr/toastr';

@import './../node_modules/toastr2/dist/toastr.min.css';

// bootstrap style toast
// or import a bootstrap 4 alert styled design (SASS ONLY)
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions
// @import './../node_modules/ngx-toastr/toastr-bs4-alert';

// // if you'd like to use it without importing all of bootstrap it requires
// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins';
// @import '~ngx-toastr/toastr-bs4-alert';

html {
  scroll-behavior: smooth;
}

button,
h1,
h2,
h3,
h4,
p,
ion-title,
ion-label,
ion-input,
ion-select,
ion-option,
ion-datetime,
ion-textarea,
div {
  font-family: 'Roboto' !important;
}

ion-card {
  margin: 10px;
  --background: #fff;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  
  // box-shadow: none;

  // border: 0.5px solid #ccc;
  ion-item {
    --background: #fff;
  }
}

// :host ::ng-deep .ngx-datatable .datatable-body .datatable-scroll {
//   display: inherit;
// }

.ngx-datatable.material {
  background: #fff;
  box-shadow: none;
}

.alert-wrapper.sc-ion-alert-ios {
  border-radius: 13px;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  width: 50%;
}


.formBuilder ion-accordion.accordion-expanding .ion-accordion-toggle-icon, ion-accordion.accordion-expanded .ion-accordion-toggle-icon {
    color: #fff !important;
}

.formBuilder ion-accordion.accordion-animated .ion-accordion-toggle-icon {
    color: #fff !important;
}

.viewRequest ion-accordion.accordion-expanding .ion-accordion-toggle-icon, ion-accordion.accordion-expanded .ion-accordion-toggle-icon {
  color: rgb(0, 0, 0) !important;
}

.viewRequest ion-accordion.accordion-animated .ion-accordion-toggle-icon {
  color: rgb(19, 19, 19) !important;
}

.appComponent ion-accordion.accordion-expanding .ion-accordion-toggle-icon, ion-accordion.accordion-expanded .ion-accordion-toggle-icon {
  color:  #fff !important;
}

.appComponent ion-accordion.accordion-animated .ion-accordion-toggle-icon {
  color:  #fff !important;
}

.dMAC ion-accordion.accordion-expanding .ion-accordion-toggle-icon, ion-accordion.accordion-expanded .ion-accordion-toggle-icon {
  color:  #fff !important;
}

.dMAC ion-accordion.accordion-animated .ion-accordion-toggle-icon {
  color:  #fff !important;
}

ion-toolbar.bar {
  --background: #ddd;
  padding: 0px 10px;
  padding-top: 10px;
}

ion-toolbar.bottombar {
  --background: #ddd;
  padding: 0px 10px;
  padding-bottom: 10px !important;
}

ion-content {
  --ion-background-color: #ddecf1;
  // --ion-background-color: #fff;
}

ion-toolbar {
  --background: #ddecf1;
  background-color: #ddecf1;
  // --background: #fff;
  // background-color: #fff;
}

ion-toolbar.sc-ion-searchbar-ios-h,
ion-toolbar .sc-ion-searchbar-ios-h {
  padding-top: 5px;
  padding-bottom: 0px;
  height: 52px;
  background-color: #ddecf1;
}

.my-custom-modal-css {
  --background: transparent !important;
  --ion-background-color: transparent !important;
}

#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1) !important;
}

.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .segment-center {
    left: 0px;
  }
  .img-header{
    height: 50px;
    padding: 5px;
  }
}
@media (min-width: 768px) {
  .segment-center {
    left: 100px;
  }
  .img-header{
    height: 60px;
    padding: 5px;
  }
}
@media (max-width: 767px) {
  .segment-center-2 {
    left: 0px;
  }
}
@media (min-width: 768px) {
  .segment-center-2 {
    left: 50px;
  }
}
@media (max-width: 767px) {
  .segment-center-3 {
    left: 0px;
  }
}
@media (min-width: 768px) {
  .segment-center-3 {
    right: 30px;
  }
}
@media (max-width: 767px) {
  .segment-center-4 {
    left: 0px;
  }
}
@media (min-width: 768px) {
  .segment-center-4 {
    left: 30px;
  }
}
@media (max-width: 767px) {
  .resetButton {
    margin-right: 0px !important ;
  }
}
@media (min-width: 768px) {
  .resetButton {
    margin-right: 30px !important ;
  }
}
@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}
ion-modal.view-table {
  --ion-background-color:var(--ion-color-light);
  --border-radius: 0px;
  --max-height: 90%;
  --max-width: 90%;
  --width: 90%;
  --border-style: solid;
  --border-color: #323232;
  --border-width: 1px;
  --min-height: 85%;
  margin-bottom: -4% !important;
}

//Apply font awesome css
.fa-fa-icons {
  font-size:24px;
}

// For popup of varient name
.alert-message.sc-ion-alert-ios {
  color: red;
}

// Added CSS for sweetalert2
div:where(.swal2-container) h2:where(.swal2-title) {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: .8em 1em 0;
  color: #000000;
  font-size: 1.175em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

div:where(.swal2-container).swal2-center>.swal2-popup {
  grid-column: 2;
  grid-row: 2;
  place-self: center center;
  width: 20%;
}

.swal2-title {
  font-size: 15px;
}
.swal2-actions{
  font-size: 10px;
}
.swal2-icon{
  font-size: 10px;
}

.viewRequest .native-input[disabled].sc-ion-input-ios {
  color: black;
  opacity: 1;
}
.item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-ios-h {
  color: black;
  opacity: 0.5;
}
